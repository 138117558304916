
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const ENTITYID="nnpickup"

const searchFields = [
	{"label":"Name", "dbfield": "nnpickup_name", "type": "text", "filtertype": "text"},
	{"label":"Type", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "text"},
	{"label":"Active", "dbfield": "nnpickup_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Hotline", "dbfield": "nnpickup_servicenumber", "type": "text", "filtertype": "text"},
	{"label":"Floor / Unit / Building", "dbfield": "nnpickup_bldgaddress", "type": "text", "filtertype": "text"},
	{"label":"Street Address", "dbfield": "nnpickup_strtaddress", "type": "text", "filtertype": "text"},
	{"label":"Municipality / City", "dbfield": "nnpickup_city", "type": "text", "filtertype": "text"},
];


const formFields = [
	{"label":"Name", "field": "nnpickup_name", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Type", "field": "onsorderformfulfillment_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Active", "field": "nnpickup_active", "value": "", "input": "checkbox", "mode": "readonly"},
	{"label":"Hotline", "field": "nnpickup_servicenumber", "value": "", "input": "smsnumber", "mode": "normal", "info":"11-digit (09nnXXXXXXX); Sent to customer order who wants assistance"},
	{"label":"Floor / Unit / Building", "field": "nnpickup_bldgaddress", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Street Address", "field": "nnpickup_strtaddress", "value": "", "input": "textarea", "mode": "readonly"},
	{"label":"Municipality / City", "field": "nnpickup_city", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Province/State", "field": "nnpickup_province", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Location Notes", "field": "nnpickup_notes", "value": "", "input": "textarea", "mode": "readonly", "info":"e.g. position from nearby landmark"},
];

const subformFields = [
	{
		"subformid": "nnpickupemail",
		"label": "email Notifications",
		"table": "nnpickupemail",
		"sort": "nnpickupemail_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "nnpickupemail_info",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnpickupemail_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "nnpickupemail_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnpickupmobile",
		"label": "SMS Notifications",
		"table": "nnpickupmobile",
		"sort": "nnpickupmobile_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "11-digit # (09nnXXXXXXX)",
				"field": "nnpickupmobile_info",
				"subtotalid": "",
				"value": "",
				"input": "smsnumber",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnpickupmobile_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "nnpickupmobile_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	}
];




const PickuppointNotificationPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["nnpickup_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}
				userSearchFilter={[{"field":"nnpickup_id", "operation":"<>", "value": 7}]}
				token={token} />
		</Layout>

}


export default PickuppointNotificationPage;
